import React from 'react'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import TwoColumn from "../../../components/two-column"
import CustomerJourney from '../../../components/customer-journey'
import Gateways from "../../../components/gateways"
// import { Helmet } from 'react-helmet'


const bannerContent = {
  title: "Make supporting your clients quicker and easier than ever before.",
  description: [
    {
      text: 'With all the information you need at your finger tips, you\'ll find it quicker and easier than ever before to service your customers and help them to manage their account with our customer service integrations.'
    }
  ],
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up for free'
    },
    {
      scheduleButton: true,
      buttonColor: 'black'
    }
  ],
  tags: [
    {
      class: 'adoric_roadmap btn-coming-soon',
      text: "COMING SOON",
      iconDisable: true,
    }
  ],
  isBanner: true,
  textColor: "#252422",
  backgroundColor: "#F3F5FB",
  sectionService: true,
  image: require('../../../images/billsby-customer-service.svg'),
  svg: true,
  imageName: 'billsby banner image',
  // comingSoon: true
}



const billsbyVault = {
  title: "All the information you need to service your customers.",
  list: [
    {
      title: 'Secure account linking',
      description: <>Our accounting integrations will securely link to your preferred solution, transferring data safely and quickly.</>,
    },
    {
      title: 'Easy to map accounts',
      description: <>We'll make it really easy to map your Billsby invoices, credit notes, refunds and bad debts with the accounts you'd like to use in your accounting package.</>,
    },
    {
      title: 'No extra charges',
      description: <>We know accounting integrations are a core feature, so we wouldn't dream of requiring you to upgrade to a premium plan to use them.</>,
    }
  ],
  backgroundImage: require('../../../images/service-headset.png'),
  bgPosition: 'right',
  image: 'service-headset.png',
  imageName: 'billsby-payment',
  hasBg: true
}

const scoreIntegrations = {
  title: "Deeper insights with Billsby Value Score integrations.",
  list: [
    {
      title: 'Rank by value score',
      description: <>We'll pass across value score data, or change the priority of tickets from customers with a high value score, to help you manage your queue.</>,
    },
    {
      title: 'Pass back value score adjustments',
      description: <>Customer service costs you money, so you might want to adjust customers value scores to better reflect the impact of their interactions on your bottom line.</>,
      buttons: [
        {
          btnColor: 'blue',
          btnText: 'Billsby Value Score',
          url: '/product/reporting/billsby-value-score'
        }
      ]
    },
  ],
  align: 'right' ,
  backgroundImage: require('../../../images/billsby-mobile.png'),
  image: 'billsby-mobile.png',
  imageName: 'billsby-mobile',
  hasBg: true
}

// const paymentIndustryCompliance = {
//   title: "Payment industry compliance is done for you, too.",
//   list: [
//     {
//       title: 'Reduce your PCI-DSS compliance burden',
//       description: <>With Billsby, cards are securely tokenised in the Billsby Vault - whichever integration approach you choose you'll never be in possession of a customers payment information. This keeps your PCI-DSS compliance burden as low as possible, with quick and simple implementation.</>,
//     },
//     {
//       title: 'Get ready for SCA',
//       description: <>SCA, or Secure Card Authentication, is the next big piece of legislation that'll affect your card payments and subscription customers. If you're using Billsby Checkout, SCA compliance will be automatically enabled on your website as it becomes necessary with no development work required.</>,
//     }
//   ]
// }

// const takeYourTokens = {
//   title: "If you ever want to leave, you can take your tokens with you.",
//   description: [
//     {
//       text: "We won't hold your tokens to ransom. If you ever want to leave Billsby we'll work with your new PCI-DSS compliant provider to arrange a token migration, and you can access all of your other data from our API."
//     },
//     {
//       text: "For our part, we'll only charge for this service based on what it actually costs us to deliver it. It's just about doing the right thing."
//     }
//   ],
//   imagePosition: 'full-right',
//   textColor: '#fff',
//   backgroundColor: '#243f5c',
//   backgroundImage: require('../../../images/doggie@2x.png'),
//   backgroundPosition: 'right',
//   sectionProtection: true,
// }

const gatewaysContent = {
  title: "Native support for a wide variety of payment gateways",
  text: "Billsby supports a broad and growing range of payment gateways, so you can choose the processor that's the best fit for your business. Support for other payment methods (like Direct Debit and ACH) is coming soon.",
  block: [
    {
      img: 'zendesk.png',
      brandName: "Adyen",
    },
    {
      img: require('../../../images/helpscout.png'),
      svg: true,
      brandName: "authorize.net",
    },
    {
      img: require('../../../images/headset.png'),
      svg: true,
      brandName: "Braintree",
    },
    {
      img: require('../../../images/intercom.png'),
      svg: true,
      brandName: "checkout.com",
    },
    {
      img: require('../../../images/drift.svg'),
      svg: true,
      brandName: "Stripe",
    },
    {
      img: require('../../../images/front.svg'),
      svg: true,
      brandName: "Stripe",
    },
  ]
}




const CustomerService = () => {
  return (
    <Layout className="customer-service">
      <SEO 
        title="Customer Service Integrations | Billsby | Powerful, customizable subscription billing software" 
        description="With all the information you need at your finger tips, you'll find it quicker and easier than ever before to service your customers and help them manage their subscription." 
        url="https://www.billsby.com/product/integrations/customer-service"
      />

      <TwoColumn content={bannerContent}/>
      <CustomerJourney content={billsbyVault} />
      <CustomerJourney content={scoreIntegrations} />
      <Gateways content={gatewaysContent} />
    </Layout>
  )
}

export default CustomerService
